<template>
  <main class="trivia content-wrapper">
    <div class="content flex">
      <h1>Trivia Nights</h1>
      <p class="lead">We host monthly trivia nights on the Gaymers UK discord server. <a href="http://join.gaymers.uk">Join us on Discord</a> to catch the next one!</p>
      <br />
      <h2>Leaderboard</h2>
      <trivia-table />
    </div>
  </main>
</template>

<script>
import TriviaTable from '@/components/TriviaTable.vue'

export default {
  name: 'Trivia',
  components: { TriviaTable }
}
</script>
