<template>
  <div class="trivia-table">
    <div class="info" v-if="info">{{ info }}</div>
    <div class="table-wrapper">
      <table class="table" v-if="ready">
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="index">
              <div class="header">{{ header }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="player in players" :key="player.name" class="row">
            <th><div class="rank">{{ player.rank }}</div></th>
            <th><div class="name">{{ player.name }}</div></th>
            <td
              v-for="(score, index) in player.scores"
              :key="index"
              :class="score"
            >
              {{ score }}
            </td>
            <td class="overall">{{ player.overall }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { GoogleSpreadsheet } from 'google-spreadsheet'

export default {
  name: 'TriviaTable',
  data () {
    return {
      sheetId: '1rE8CnYD5NYzststiyMNuQmPI-SfMA1M9zAOoZMxll6E',
      apiKey: 'AIzaSyB1cw_260HyutjF4QaGAzUGXlgnB5bNt1g',
      headers: [],
      players: null,
      state: 'none'
    }
  },
  computed: {
    ready () {
      return this.state === 'ready'
    },
    info () {
      switch (this.state) {
        case 'loading':
          return 'Fetching the latest data...'
        case 'error':
          return 'An unexpected error has occurred.'
        default:
          return null
      }
    }
  },
  methods: {
    async fetchData () {
      this.state = 'loading'
      try {
        const doc = new GoogleSpreadsheet(this.sheetId)
        await doc.useApiKey(this.apiKey)
        await doc.loadInfo()
        const sheet = doc.sheetsByIndex[0]

        await sheet.loadHeaderRow()
        this.headers = sheet.headerValues.slice(1)

        const rows = await sheet.getRows()
        this.players = rows.map(row => {
          const cols = Array.from(row._rawData)
          const showLow = cols.shift() === '1'
          return {
            rank: cols.shift(),
            name: cols.shift(),
            overall: cols.pop(),
            scores: showLow ? cols : cols.map(score => score <= 50 ? '≤ 50' : score)
          }
        })
        this.state = 'ready'
      } catch (error) {
        this.state = 'error'
        this.error = error
        console.error(error)
      }
    }
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.trivia-table {
  margin-top: 1rem;
  overflow: hidden;

  .info {
    border-radius: 5px;
    background: var(--c-bg-shade);
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .table-wrapper {
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    border-radius: 5px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--c-primary);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover{
      background: var(--c-primary-shade);
    }

    &::-webkit-scrollbar-track{
      background: var(--c-shadow);
      border-radius: 10px;
    }

    .table {
      width: 100%;
      margin: auto;
      border-collapse: separate;
      border-spacing: 0;

      col:first-child {
        width: 3rem;
      }

      thead {
        th {
          text-align: center;
          padding: 0.5rem 0.75rem;
          font-family: 'Open Sans Condensed', 'Open Sans', sans-serif;
          font-weight: 700;
          background: var(--c-bg-shade);

          &:last-child {
            font-weight: 700;
          }

          &:nth-child(1),
          &:nth-child(2) {
            box-shadow: 3px 0 3px -1px var(--c-shadow);
          }

          @include breakpoint(md) {
            &:nth-child(1),
            &:nth-child(2) {
              position: sticky;
              left: 0;
            }

            &:nth-child(2) {
              left: 3rem;
            }
          }
        }
      }

      tbody {
        tr {
          height: 2rem;

          &:nth-child(even) {
            background: var(--c-bg-shade);
          }

          &:nth-child(1) {
            .name {
              color: gold;
            }
          }

          &:nth-child(2) {
            .name {
              color: lightskyblue;
            }
          }

          &:nth-child(3) {
            .name {
              color: chocolate;
            }
          }

          th {
            background: var(--c-bg-shade);
            white-space: nowrap;

            &:nth-child(2) {
              padding: 0 0.5rem;
            }

            @include breakpoint(md) {
              position: sticky;
              left: 0;
              z-index: 2;

              &:nth-child(2) {
                left: 3rem;
                box-shadow: 3px 0 3px -1px var(--c-shadow);
              }
            }

            .rank {
              text-align: right;
              width: 3rem;
              color: var(--c-primary);
            }

            .name {
              text-align: left;
            }
          }

          td {
            text-align: center;

            &.DNE {
              color: var(--c-text-muted);
            }

            &.Host {
              color: $green;
            }

            &.Author {
              color: $blue;
            }

            &.overall {
              color: var(--c-primary);
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
</style>
